.container {
    display: flex;
    justify-content: center;
    margin-block-start: var(--margin-big);

    .contentContainer {
        width: 90%;
        max-width: 700px;
        position: relative;
    }

    @media screen and (min-width: 500px) {
        .contentContainer {
            width: 75%;
        }
    }

    @media screen and (min-width: 767px) {
        .contentContainer {
            width: 50%;
        }
    }

    @media screen and (min-width: 1200px) {
        .contentContainer {
            width: 33%;
        }
    }

    .errorAlert {
        margin-block-end: var(--padding);
        text-align: center;
    }
}

.card {
    align-items: center;
    justify-content: center;
    display: flex;
}

.inner {
    width: 70px;
    padding-block: 3em;
}

.spinner {
    stroke: var(--brand-color);
}
