.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-block-start: 4em;
    padding-block-end: 2em;
}

.header img {
    max-width: 31.25em;
}
